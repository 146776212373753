/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './backdrop.style';

const Backdrop = ({ classes, open, click, zIndex }) => {
  const escKeyDownHandler = (event) => {
    if (event.keyCode === 27) {
      click();
    }
  };

  return (
    <div
      className={open ? classes.backdrop : classes.noBackdrop}
      onClick={click}
      role="presentation"
      onKeyDown={escKeyDownHandler}
      style={zIndex ? { zIndex: +zIndex - 50 } : null}
    >
      {/* <CloseIcon className={classes.icon} onClick={() => {console.log("Hii There")}}/> */}
    </div>
  );
};

Backdrop.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  click: PropTypes.func,
  zIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Backdrop.defaultProps = {
  zIndex: 0,
  click: () => {},
};
export default withStyles(styles)(Backdrop);
