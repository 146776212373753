/* eslint-disable */
import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const StatusFilter = ({
  label,
  onChange,
  value,
  multiple,
  statusList,
  required,
}) => {
  return (
    <TextField
      label={label}
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      required={required}
      value={value}
      select
      SelectProps={{ multiple: multiple ? true : false }}
      variant="outlined"
    >
      {statusList &&
        statusList.length &&
        statusList.map((type) => (
          <MenuItem value={type.id} key={type.id} name={type.status_name}>
            {type.status_name ? type.status_name : ""}
          </MenuItem>
        ))}
    </TextField>
  );
};

StatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  // value: PropTypes.oneOfType[(PropTypes.string.isRequired, PropTypes.array.isRequired)],
  multiple: PropTypes.bool,
  statusList: PropTypes.array.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

StatusFilter.defaultProps = {
  multiple: false,
  label: "Lead Status",
  required: true,
};

export default StatusFilter;
