/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '../paper/customPaper'
import {
  IconButton,
  InputBase, 
  withStyles 
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';

const styles = theme => ({
  paper: {
    height: 35,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 10,
    boxShadow: '1px 1px 3px 1px #9E9E9E',
    borderRadius: 10,
  }
});

const CustomSearch = ({ classes, search, handleSearchText, handleClose}) => {
  return (
    <Paper className={classes.paper}>
      <InputBase
        placeholder="Search"
        value={search}
        onChange={handleSearchText}
        style={{ width: '100%' }}
      />
      {search === ''
        ? (
          <IconButton aria-label="search">
            <Search />
          </IconButton>
        ) : (
          <IconButton type="submit" aria-label="search" onClick={handleClose}>
            <Close />
          </IconButton>
        )
      }
    </Paper>
  )
};

CustomSearch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  search: PropTypes.string.isRequired,
  handleSearchText: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomSearch);
