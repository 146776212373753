/* eslint-disable */
import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const SourceTypeFilter = ({ onChange, value, multiple, sourceTypeList, label }) => {
  return (
    <TextField
      label={label}
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      required
      value={value}
      select
      SelectProps={{ multiple: multiple ? true : false }}
      variant="outlined"
    >
      {sourceTypeList &&
        sourceTypeList.length &&
        sourceTypeList.map((type) => (
          <MenuItem value={type.id} key={type.id} name={type.type_name}>
            {type.type_name ? type.type_name : ""}
          </MenuItem>
        ))}
    </TextField>
  );
};

SourceTypeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  // value: PropTypes.oneOfType[(PropTypes.string.isRequired, PropTypes.array.isRequired)],
  multiple: PropTypes.bool,
  sourceTypeList: PropTypes.array.isRequired,
  label: PropTypes.string,
};

SourceTypeFilter.defaultProps = {
  multiple: false,
  label: 'Source Type'
};

export default SourceTypeFilter;
