/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const TimerOtp = ({ clock, status, resundOtpEnable }) => {
  const [duration, setDuration] = useState(parseInt(clock, 10));
  useEffect(() => {
    setDuration(clock);
  }, [status]);
  useEffect(() => {
    if (duration === 0) {
      resundOtpEnable()
    }
  }, [duration]);
  function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  useInterval(() => {
    if (duration > 0) {
      setDuration((t) => t - 1);
    }
  }, [1000]);
  const time = duration;
  return (
    <span>
    {time}
  </span>
  );
};

TimerOtp.propTypes = {
  clock: PropTypes.number.isRequired,
  status: PropTypes.bool.isRequired,
  resundOtpEnable: PropTypes.func.isRequired,
};

export default TimerOtp;