/* eslint-disable */
import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const BranchFilter = ({ onChange, value, multiple, branchesList, style }) => {
  return (
    <TextField
      label="Branch"
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      required
      value={value}
      style={style}
      select
      SelectProps={{ multiple: multiple ? true : false }}
      variant="outlined"
    >
      {branchesList &&
        branchesList.length ?
        branchesList.map((type) => (
          <MenuItem value={type.id} key={type.id} name={type.branch_name}>
            {type.branch_name ? type.branch_name : ""}
          </MenuItem>
        )) : null}
    </TextField>
  );
};

BranchFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  // value: PropTypes.oneOfType[(PropTypes.string.isRequired, PropTypes.array.isRequired)],
  multiple: PropTypes.bool,
  branchesList: PropTypes.array.isRequired,
  style: PropTypes.object
};

BranchFilter.defaultProps = {
  multiple: false,
  style: null,
};

export default BranchFilter;
