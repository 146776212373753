/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import {
  TextField,
  Popper,
  Paper,
  Fade,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const AutoCompleteText = (props) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const autoRef = useRef();
  const [open, setOpen] = useState(false);
  const [textFieldWidth, setTextFieldWidth] = useState('');

  const {
    data,
    fullWidth,
    className,
    label,
    margin,
    onChange,
    required,
    variant,
    value,
  } = props;

  useEffect(() => {
    if (value && value.length >= 3) {
      setOpen(true);
    }
    if (value && value.length < 3) {
      setOpen(false);
    }
  }, [value, value.length]);

  useEffect(() => {
    document.addEventListener('click', () => {
      setOpen(false);
    });
    setTextFieldWidth(autoRef.current.offsetWidth);
    return () => {
      document.removeEventListener('click', () => { setOpen(false); });
    };
  }, []);

  const itemClickHandler = (item) => {
    const event = {
      target: {
        value: item.value,
        label: item.label,
      },
    };
    props.onChange(event, true);
  };

  let textValue = '';
  if ((typeof value) === 'number') {
    textValue = props.data.filter(item => item.value === +props.value)[0].label;
  } else {
    textValue = props.value;
  }

  return (
    <React.Fragment>
      <div style={{ position: 'absolute', top: '1px', left: '1px' }}>
        <Popper open={open} anchorEl={autoRef.current} placement="bottom-start" transition style={{ zIndex: '1200', width: textFieldWidth }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{}}>
                {data ? (
                  <List component="nav">
                    {data.map((item, index) => {
                      if (index < 6) {
                        return (
                          <ListItem button onClick={() => itemClickHandler(item)} key={item.label}>
                            <ListItemText>{item.label}</ListItemText>
                          </ListItem>
                        );
                      }
                      return null;
                    })}
                  </List>
                ) : null}
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
      <TextField
        style={{ position: 'relative' }}
        inputRef={autoRef}
        fullWidth={fullWidth}
        className={className}
        label={label}
        margin={margin}
        onChange={event => onChange(event, false)}
        required={required}
        value={textValue || ''}
        variant={variant}
      />
    </React.Fragment>
  );
};

AutoCompleteText.propTypes = {
  data: PropTypes.instanceOf(Array),
  fullWidth: PropTypes.bool,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

AutoCompleteText.defaultProps = {
  onChange: () => {},
  data: [],
  required: false,
  fullWidth: false,
};

export default AutoCompleteText;
