/* eslint-disable */
import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useAcademicYear } from "../../customHooks";

const AcademicYearFilter = ({ onChange, value, multiple }) => {
  const academicYearList = useAcademicYear();
  return (
    <TextField
      label="Academic Year"
      margin="dense"
      fullWidth
      size="small"
      onChange={onChange}
      required
      value={value}
      select
      SelectProps={{ multiple: multiple ? true : false }}
      variant="outlined"
    >
      {academicYearList &&
        academicYearList.length &&
        academicYearList.map((type) => (
          <MenuItem
            value={type.session_year}
            key={type.id}
            name={type.session_year}
          >
            {type.session_year ? type.session_year : ""}
          </MenuItem>
        ))}
    </TextField>
  );
};

AcademicYearFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  // value: PropTypes.oneOfType[(PropTypes.string.isRequired, PropTypes.array.isRequired)],
  multiple: PropTypes.bool,
};

AcademicYearFilter.defaultProps = {
  multiple: false,
};

export default AcademicYearFilter;
