/* eslint-disable */
export default (theme) => ({
  modal : {
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    position: 'fixed',
    width: '50%',
    minWidth: '40%',
    margin: 'auto',
    maxHeight : '95vh !important',
    minHeight: '0px !important',
    zIndex: 1300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  modal__small : {
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    position: 'fixed',
    width: '30%',
    minWidth: '30%',
    margin: 'auto',
    maxHeight: '55vh !important',
    minHeight: '0px !important',
    zIndex: 1300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    overflow: 'auto',
  },
  modal__medium : {
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    position: 'fixed',
    width: '40%',
    minWidth: '40%',
    margin: 'auto',
    maxHeight: '75vh !important',
    minHeight: '0px !important',
    zIndex: 1300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    overflow: 'auto',
  },
  modal__large : {
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    position: 'fixed',
    width: '80%',
    minWidth: '80%',
    margin: 'auto',
    maxHeight: '90vh !important',
    minHeight: '0px !important',
    zIndex: 1300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    overflow: 'auto',
  },
  modal__order : {
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    position: 'fixed',
    width: '90%',
    minWidth: '90%',
    margin: 'auto',
    maxHeight: '95vh !important',
    minHeight: '0px !important',
    zIndex: 1300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fafafa',
    overflow: 'auto',
  },
  modal__image :{
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    position: 'fixed',
    maxwidth: '80%',
    minWidth: '60%',
    margin: 'auto',
    maxHeight: '95vh !important',
    minHeight: '0px !important',
    zIndex: 1300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    overflow: 'auto',
    '@media only screen and (max-width: 767px)' : {
      maxWidth: '90%',
      minWidth: '90%',
    },
    '@media only screen and (max-width: 1025px)' : {
      maxWidth: '80%',
      minWidth: '80%',
    },
  },
  noModal : {
    display: 'none',
  },
});
