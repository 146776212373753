/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const Timer = ({ clock, status, resundOtpEnable }) => {
  const [duration, setDuration] = useState(parseInt(clock, 10));
  useEffect(() => {
    setDuration(clock);
  }, [status]);
  useEffect(() => {
    if (duration === 0) {
      resundOtpEnable()
    }
  }, [duration]);
  function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  useInterval(() => {
    if (duration > 0) {
      setDuration((t) => t - 1);
    }
  }, [1000]);
  const time = duration;
  const seconds = Math.floor(time % 60);
  const minutes = Math.floor((time / 60) % 60);
  const hours = Math.floor(time / 3600);
  return (
    <span>
      {hours}
      &nbsp;:&nbsp;
      {minutes}
      &nbsp;:&nbsp;
      {seconds > 9 ? seconds : `0${seconds}`}
    </span>
  );
};

Timer.propTypes = {
  clock: PropTypes.number.isRequired,
  status: PropTypes.bool.isRequired,
  resundOtpEnable: PropTypes.func.isRequired,
};

export default Timer;